/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Barlow Condensed', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.selector {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* For older versions of WebKit-based browsers */
/* This is a fallback for WebKit browsers that don't support `font-smooth` */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .selector {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.mantine-ygn8l0 {
  background-color: transparent !important;
}

.mantine-1gbfnu2 {
  background-color: transparent !important;
}

.mantine-vn8k6y {
  background-color: transparent !important;
}

.mantine-ip24me {
  background-color: transparent !important;
}

.mantine-1vibmw7::before {
  background-color: transparent !important;
}


.mantine-1682jzp {
  min-height: auto;
}


.mantine-1682jzp {
  padding: 0;
}

.mantine-1q36a81 {
  padding: 0;
}

.mantine-1eopbn3::before {
  background-color: transparent !important;
}
.mantine-15wqd5p {
  background-color: transparent !important;
}

.mantine-xppf8t {
  background-color: transparent !important;
}

.mantine-i4faud {
  background-color: transparent !important;
}

.mantine-172hskf {
  background-color: transparent !important;
}

.mantine-1skrazn {
  background-color: transparent !important;
}

.mantine-c58ew8 {
  width: 45% !important;
}

